body {
	position: relative;
	margin: 0;
	font-family: monospace;
	font-size: 14px;
	letter-spacing: -0.5px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	width: 100%;
	height: 100%;

	color: white;
	background: #0b0514;

	&::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
